import React, { CSSProperties } from 'react';
import { GatsbyImage, GatsbyImageProps, IGatsbyImageData } from 'gatsby-plugin-image';

import { ratioContainer, imgBox, img, ordinaryImg } from './ratio-image.module.scss';

export interface IRatioImageProps {
    className?: string;
    ratioClass?: string;
    image: IGatsbyImageData | undefined;
    isOrdinaryImg?: boolean;
    alt?: string;
    objectFit?: CSSProperties['objectFit'];
    objectPosition?: CSSProperties['objectPosition'];
    loading?: GatsbyImageProps['loading'];
}

const RatioImage: React.FC<IRatioImageProps> = ({
    className = '',
    ratioClass = '',
    image,
    isOrdinaryImg = false,
    alt = '',
    objectFit = 'cover',
    objectPosition = 'center center',
    loading,
}) => {
    const imgSources = image?.images.sources;
    const imgSource = imgSources?.length && imgSources.length > 0 ? imgSources[0] : undefined;
    return (
        <div className={className}>
            <div className={`${ratioContainer} ${ratioClass}`}>
                <div className={imgBox}>
                    {image &&
                        (isOrdinaryImg ? (
                            <img
                                sizes={imgSource?.sizes || ''}
                                srcSet={imgSource?.srcSet || ''}
                                alt=""
                                className={ordinaryImg}
                                style={{
                                    objectFit,
                                    objectPosition,
                                }}
                            />
                        ) : (
                            <GatsbyImage
                                className={img}
                                image={image}
                                alt={alt || ''}
                                objectFit={objectFit}
                                objectPosition={objectPosition}
                                loading={loading}
                            />
                        ))}
                </div>
            </div>
        </div>
    );
};

export default RatioImage;
