import React from 'react';

import { contentBox, leadText, meta, tag, titleText } from './post-body.module.scss';
import { translationKeys } from '../../config/translation-keys';
import { IPost } from '../../models/post.model';
import { useTranslation } from '../../hooks/use-translation';

import PostMeta from '../molecules/post-meta';
import Markdown from '../hoc/markdown';
import SocialShare from './social-share';
import PostBox from '../atoms/post-box';

interface IPostBodyProps {
    className?: string;
    post: IPost;
}

const PostBody: React.FC<IPostBodyProps> = ({ className = '', post }) => {
    const {
        author: { profileId },
        publishedAt,
        timeRead,
        tags,
        tagsPriority,
        updatedAt,
        box1,
        box2,
        box3,
    } = post;
    const { titleWithoutOrphans, lead, content, content2, content3 } = useTranslation(
        post,
        translationKeys.post
    );

    const mainTagId = tagsPriority.find((tagPriority) => tagPriority.isMain)?.tagId;
    const mainTag = tags.find((tag) => tag.tagId === mainTagId);
    const { name } = useTranslation(mainTag, translationKeys.tag);

    return (
        <article className={className}>
            {name && <p className={tag}>{name}</p>}
            <h1 className={titleText}>{titleWithoutOrphans}</h1>
            <PostMeta
                profileId={profileId}
                publishedAt={publishedAt}
                updatedAt={updatedAt}
                timeRead={timeRead}
                className={meta}
            />
            <SocialShare iconJustify="flex-start" />
            {lead && <Markdown className={leadText}>{`<strong>${lead}</strong>`}</Markdown>}
            {content && <Markdown className={contentBox}>{content}</Markdown>}
            {box1 && <PostBox box={box1} />}
            {content2 && <Markdown className={contentBox}>{content2}</Markdown>}
            {box2 && <PostBox box={box2} />}
            {content3 && <Markdown className={contentBox}>{content3}</Markdown>}
            {box3 && <PostBox box={box3} />}
        </article>
    );
};

export default PostBody;
