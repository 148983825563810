import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';

import {
    container,
    img,
    imageWithLink,
    imageWithTextAndLink,
    textWithButton,
    title as titleClass,
    lead as leadClass,
    link as linkClass,
    button as buttonClass,
} from './post-box.module.scss';
import { EViewType, IPostBox } from '../../models/post.model';
import { translationKeys } from '../../config/translation-keys';
import { relations } from '../../config/relations';
import { useTranslation } from '../../hooks/use-translation';
import useMedia from '../../hooks/use-media';
import { isInternalLink } from '../../utils/is-internal-link';
import { getInteractiveElementTag } from '../../utils/get-interactive-element';

interface IPostBoxProps {
    className?: string;
    box: IPostBox;
}

const PostBox: React.FC<IPostBoxProps> = ({ className = '', box }) => {
    const { hostname } = useLocation();
    const { title, lead, url, buttonText } = useTranslation(box, translationKeys.postBox);
    const { viewType } = box;
    const image = useMedia({ media: box.media, relation: relations.mainImage });
    const linkType = isInternalLink(url, hostname);
    const TagElement = getInteractiveElementTag(linkType ? 'link' : 'externalLink');
    const elementClassName = `${getTypeClass(viewType)} ${container} ${className}`;
    const linkSettings = linkType
        ? {
              to: url,
          }
        : {
              href: url,
              rel: 'noreferrer noopener',
              target: '_blank',
          };

    return (
        <TagElement {...linkSettings} className={elementClassName}>
            {isImage(viewType) && image.image && (
                <GatsbyImage
                    className={img}
                    image={image.image}
                    alt={image.alt || ''}
                    objectFit={'cover'}
                    objectPosition={'center'}
                />
            )}

            <div>
                {isText(viewType) && title && <h3 className={titleClass}>{title}</h3>}
                {isText(viewType) && lead && <p className={leadClass}>{lead}</p>}
                {!isButton(viewType) && buttonText && <p className={linkClass}>{buttonText}</p>}
            </div>

            {isButton(viewType) && buttonText && <p className={buttonClass}>{buttonText}</p>}
        </TagElement>
    );
};

function isImage(viewType: IPostBox['viewType']): boolean {
    return viewType === EViewType.ImageWithLink || viewType === EViewType.ImageWithTextAndLink;
}

function isText(viewType: IPostBox['viewType']): boolean {
    return viewType === EViewType.TextWithButton || viewType === EViewType.ImageWithTextAndLink;
}

function isButton(viewType: IPostBox['viewType']): boolean {
    return viewType === EViewType.TextWithButton;
}

function getTypeClass(viewType: IPostBox['viewType']): string {
    switch (viewType) {
        case EViewType.ImageWithLink:
            return imageWithLink;
        case EViewType.ImageWithTextAndLink:
            return imageWithTextAndLink;
        default:
            return textWithButton;
    }
}

export default PostBox;
