import { IMedia } from './media.model';
import { ITag } from './tag.model';
import { IAuthor } from './author.model';
import { ITagPriority } from './tag-priority.model';

interface IPostBase {
    articleId: number;
    publishedAt: number;
    updatedAt: number;
    slug: string;
}

export interface IPostCardTranslation {
    title: string;
    titleWithoutOrphans: string;
    lead: string;
}

export interface IPostCard extends IPostBase {
    media: IMedia[];
    tags: ITag[];
    tagsPriority: ITagPriority[];
    translations: {
        [key: string]: IPostCardTranslation;
    };
}

export interface IPostBoxTranslation {
    title: string;
    lead: string;
    url: string;
    buttonText: string;
}

export enum EViewType {
    ImageWithLink = 0,
    TextWithButton = 1,
    ImageWithTextAndLink = 2,
}

export interface IPostBox {
    articleId: number;
    slug: string;
    viewType: EViewType;
    translations: {
        [key: string]: IPostBoxTranslation;
    };
    media: IMedia[];
}

export interface IPostTranslation {
    title: string;
    titleWithoutOrphans: string;
    lead?: string;
    content?: string;
    content2?: string;
    content3?: string;
}

export interface IPost extends IPostBase {
    author: Pick<IAuthor, 'profileId'>;
    timeRead: number;
    tags: ITag[];
    tagsPriority: ITagPriority[];
    links: string[];
    translations: {
        [key: string]: IPostTranslation;
    };
    box1: IPostBox;
    box2: IPostBox;
    box3: IPostBox;
}
