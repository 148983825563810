import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, meta, divider, featured } from './post-meta.module.scss';
import { translationKeys } from '../../config/translation-keys';
import { IAuthor } from '../../models/author.model';
import { IPost } from '../../models/post.model';
import { useTranslation } from '../../hooks/use-translation';
import { formatUnixTimestamp } from '../../utils/format-unix-timestamp';
import { useAuthor } from '../../contexts/author-context';

interface IPostMetaProps {
    className?: string;
    profileId: IAuthor['profileId'];
    publishedAt: IPost['publishedAt'];
    updatedAt: IPost['updatedAt'];
    timeRead: number;
}

const PostMeta: React.FC<IPostMetaProps> = ({
    className = '',
    profileId,
    publishedAt,
    updatedAt,
    timeRead,
}) => {
    const { t } = useI18next();
    const author = useAuthor(profileId);
    const { firstName = '', lastName = '' } = useTranslation(author, translationKeys.author);
    return (
        <p className={`${container} ${className}`}>
            <span className={meta}>
                {t('post.meta.author')}{' '}
                <span className={featured}>
                    {firstName} {lastName}
                </span>
            </span>{' '}
            {updatedAt && (
                <span className={`${meta} ${divider}`}>
                    {t('post.meta.update')}{' '}
                    <span className={featured}>{formatUnixTimestamp(updatedAt)}</span>
                </span>
            )}{' '}
            <span className={`${meta} ${divider}`}>
                {t('post.meta.publishing')}{' '}
                <span className={featured}>{formatUnixTimestamp(publishedAt)}</span>
            </span>
            <span className={`${meta} ${divider}`}>
                {t('post.meta.readTime')}{' '}
                <span className={featured}>
                    {timeRead} {t('post.meta.readTimeUnit')}
                </span>
            </span>
        </p>
    );
};

export default PostMeta;
