export type TRelationKey =
    | 'mainImage'
    | 'secondaryImage'
    | 'mainImageMobile'
    | 'coverImage'
    | 'ogImage'
    | 'coverImageHorizontal'
    | 'coverImageVertical';

export type TRelations = Record<TRelationKey, number>;

export const relations: TRelations = {
    mainImage: 1,
    secondaryImage: 2,
    mainImageMobile: 3,
    coverImage: 4,
    ogImage: 6,
    coverImageHorizontal: 101,
    coverImageVertical: 102,
};
