import React from 'react';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';

import {
    article,
    container,
    contentBox,
    titleText,
    tagName,
    image,
    excerpt,
    imageBox,
} from './simple-post-card.module.scss';
import { IPostCard } from '../../models/post.model';
import { translationKeys } from '../../config/translation-keys';
import { relations } from '../../config/relations';
import { useTranslation } from '../../hooks/use-translation';
import useMedia from '../../hooks/use-media';

import RatioImage from '../atoms/ratio-image';

interface ISimplePostCardProps {
    className?: string;
    TitleElement?: React.ElementType;
    TagElement?: React.ElementType;
    postCard: IPostCard;
}

const SimplePostCard: React.FC<ISimplePostCardProps> = ({
    className = '',
    TitleElement = 'h2',
    TagElement = 'h3',
    postCard,
}) => {
    const { slug, media, tags, tagsPriority } = postCard;

    const mainTagId = tagsPriority.find((tagPriority) => tagPriority.isMain)?.tagId;
    const mainTag = tags.find((tag) => tag.tagId === mainTagId);
    const img = useMedia({
        media,
        relation: relations.coverImageHorizontal,
        fallback: relations.coverImage,
    });

    const { titleWithoutOrphans, lead } = useTranslation(postCard, translationKeys.postCard);
    const { name } = useTranslation(mainTag, translationKeys.tag);

    return (
        <article className={`${className} ${article}`}>
            <Link className={container} to={slug}>
                <RatioImage
                    image={img.image}
                    alt={img.alt || ''}
                    ratioClass={image}
                    className={imageBox}
                />
                <div className={contentBox}>
                    {mainTag && <TagElement className={tagName}>{name}</TagElement>}
                    <TitleElement className={titleText}>{titleWithoutOrphans}</TitleElement>
                    <p className={excerpt}>{lead}</p>
                </div>
            </Link>
        </article>
    );
};

export default SimplePostCard;
