// extracted by mini-css-extract-plugin
export var authorBox = "post-page-module--author-box--CaD9Q";
export var layout = "post-page-module--layout--pONyJ";
export var linkList = "post-page-module--link-list--5kAmf";
export var postBody = "post-page-module--post-body--Y-WiK";
export var relatedContainer = "post-page-module--related-container--Ep8Yj";
export var relatedHeading = "post-page-module--related-heading--XIhcF";
export var relatedPostCards = "post-page-module--related-post-cards--+mPqI";
export var share = "post-page-module--share--1jvtL";
export var shareList = "post-page-module--share-list--pqJdU";
export var shareTitle = "post-page-module--share-title--ZyKgp";