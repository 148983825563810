import React from 'react';
import { motion, Variants } from 'framer-motion';

import {
    container,
    content,
    contentBox,
    modalDesktop,
    modalMobile,
} from './content-layout.module.scss';
import { PAGE_CHANGE_DURATION } from '../config/consts';

import MainLayout, { IMainLayoutProps } from './main-layout';
import Modal from '../components/hoc/modal';

interface IContentLayoutProps extends IMainLayoutProps {
    excludeAsidePostId?: number;
    postMainTagId?: number;
    mainClassName?: string;
}

const contentVariants: Variants = {
    start: {
        x: '-120%',
        transition: {
            duration: PAGE_CHANGE_DURATION,
        },
    },
    done: {
        x: 0,
        transition: {
            duration: PAGE_CHANGE_DURATION,
        },
    },
    finish: {
        x: '-120%',
        transition: {
            duration: PAGE_CHANGE_DURATION,
        },
    },
};

const ContentLayout: React.FC<IContentLayoutProps> = ({
    SEOProps,
    className,
    mainClassName = '',
    children,
}) => {
    return (
        <MainLayout SEOProps={SEOProps} className={mainClassName}>
            <div className={container}>
                <motion.div
                    className={content}
                    variants={contentVariants}
                    initial="start"
                    animate="done"
                    exit="finish"
                >
                    <div className={`${contentBox} ${className}`}>{children}</div>
                    <Modal className={modalDesktop} position="absolute" />
                </motion.div>
                <Modal className={modalMobile} position="fixed" />
            </div>
        </MainLayout>
    );
};

export default ContentLayout;
