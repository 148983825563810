import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {
    FacebookShareButton,
    FacebookMessengerShareButton,
    LinkedinShareButton,
    EmailShareButton,
    TwitterShareButton,
} from 'react-share';
import { useLocation } from '@reach/router';

import { container, icon } from './social-share.module.scss';

import IconButton, { IIconButtonProps } from '../atoms/icon-button';

interface ISocialShareProps {
    className?: string;
    iconJustify?: IIconButtonProps['justify'];
    iconSize?: IIconButtonProps['size'];
}

const SocialShare: React.FC<ISocialShareProps> = ({ className = '', iconJustify, iconSize }) => {
    const { t } = useI18next();
    const { href } = useLocation();

    const sharedIconButtonProps: IIconButtonProps = {
        as: 'element',
        color: 'accent',
        justify: iconJustify,
        size: iconSize,
        disableDefaultHover: true,
        className: icon,
    };

    return (
        <div className={`${container} ${className}`}>
            <LinkedinShareButton
                url={href}
                aria-label={t('share.linkedin')}
                title={t('share.linkedin')}
            >
                <IconButton icon="linkedinCircle" {...sharedIconButtonProps} />
            </LinkedinShareButton>
            <TwitterShareButton
                url={href}
                aria-label={t('share.twitter')}
                title={t('share.twitter')}
            >
                <IconButton icon="twitterCircle" {...sharedIconButtonProps} />
            </TwitterShareButton>
            <FacebookShareButton
                url={href}
                aria-label={t('share.facebook')}
                title={t('share.facebook')}
            >
                <IconButton icon="facebookCircle" {...sharedIconButtonProps} />
            </FacebookShareButton>
            <EmailShareButton url={href} aria-label={t('share.email')} title={t('share.email')}>
                <IconButton icon="envelopeCircle" {...sharedIconButtonProps} />
            </EmailShareButton>
            <FacebookMessengerShareButton
                url={href}
                aria-label={t('share.messenger')}
                title={t('share.messenger')}
                appId={process.env.FACEBOOK_APP_ID || ''}
            >
                <IconButton icon="messengerCircle" {...sharedIconButtonProps} />
            </FacebookMessengerShareButton>
        </div>
    );
};

export default SocialShare;
