export function formatUnixTimestamp(unixTimestamp: number, separator: string = '.'): string {
    const timestamp = unixTimestamp * 1000;
    const date = new Date(timestamp);

    let day = date.getDate().toString();
    if (day.length === 1) {
        day = `0${day}`;
    }
    let month = (date.getMonth() + 1).toString();
    if (month.length === 1) {
        month = `0${month}`;
    }
    const year = date.getFullYear();

    return `${day}${separator}${month}${separator}${year}`;
}
