import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    layout,
    authorBox,
    postBody,
    linkList,
    shareList,
    share,
    shareTitle,
    relatedContainer,
    relatedHeading,
    relatedPostCards,
} from './post-page.module.scss';
import { relations } from '../config/relations';
import { translationKeys } from '../config/translation-keys';
import { ISitePageContext } from '../models/site-page.model';
import { IPost } from '../models/post.model';
import { useTranslation } from '../hooks/use-translation';
import { AuthorsContextProvider } from '../contexts/author-context';
import { usePostCards, useSinglePostCard } from '../contexts/post-cards-context';
import useMedia from '../hooks/use-media';

import ContentLayout from '../layouts/content-layout';
import PostBody from '../components/organisms/post-body';
import Author from '../components/organisms/author';
import PostLinks from '../components/organisms/post-links';
import SocialShare from '../components/organisms/social-share';
import SimplePostCard from '../components/molecules/simple-post-card';

interface IPostPageProps {
    readonly pageContext: ISitePageContext;
    readonly data: {
        post: IPost;
    };
}

const NUMBER_OF_POST_CARDS = 4;

const PostPage: React.FC<IPostPageProps> = ({ data }) => {
    const { t } = useI18next();
    const { post } = data;
    const {
        author: { profileId },
        articleId,
        links,
    } = post;
    const { title, lead } = useTranslation(post, translationKeys.post);

    const { media, tagsPriority } = useSinglePostCard(articleId) || {};
    const ogImage = useMedia({
        media,
        relation: relations.ogImage,
        fallback: relations.coverImage,
    });
    const mainPostTag = tagsPriority?.find((tagsPriority) => tagsPriority.isMain);
    const postCards = usePostCards({ tagId: mainPostTag?.tagId, excludeId: articleId });
    const postCardsToDisplay = postCards.slice(0, NUMBER_OF_POST_CARDS);

    return (
        <AuthorsContextProvider>
            <ContentLayout
                className={layout}
                SEOProps={{
                    title: title,
                    description: lead,
                    image: ogImage.url,
                    isArticle: true,
                }}
            >
                <PostBody post={post} className={postBody} />
                <div className={share}>
                    <h2 className={shareTitle}>{t('post.share.title')}</h2>
                    <SocialShare className={shareList} iconJustify="center" iconSize="large" />
                </div>
                {links.length > 0 && <PostLinks links={links} className={linkList} />}
                {profileId && <Author profileId={profileId} className={authorBox} />}
                {postCardsToDisplay.length > 0 && (
                    <section className={relatedContainer}>
                        <h2 className={relatedHeading}>{t('post.related')}</h2>
                        <div className={relatedPostCards}>
                            {postCardsToDisplay.map((postCard, index) => {
                                return (
                                    <SimplePostCard
                                        TitleElement={'h3'}
                                        TagElement={'p'}
                                        key={`simple-post-card-${postCard.articleId}-${index}`}
                                        postCard={postCard}
                                    />
                                );
                            })}
                        </div>
                    </section>
                )}
            </ContentLayout>
        </AuthorsContextProvider>
    );
};

export const query = graphql`
    query ($language: String!, $articleId: Int!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        post(articleId: { eq: $articleId }) {
            ...postFields
        }
    }
`;

export default PostPage;
