import { useEffect, useState } from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';
import { getImage } from 'gatsby-plugin-image';

import { IMedia } from '../models/media.model';
import { getMediaWithRelation } from '../utils/get-media-with-relation';

export interface IUseMedia {
    media: IMedia[] | undefined;
    relation: number;
    fallback?: number;
}

interface IMediaImage {
    image?: IGatsbyImageData;
    alt: string;
    url: string;
}

type UseMediaReturnValue = [IGatsbyImageData | undefined, string, string] & Partial<IMediaImage>;

export default function useMedia({ media, relation, fallback }: IUseMedia): UseMediaReturnValue {
    const [mediaImage, setMediaImage] = useState<IMediaImage>(
        getMediaImage(media, relation, fallback)
    );

    useEffect(() => {
        setMediaImage(getMediaImage(media, relation, fallback));
    }, [media, relation]);

    const mediaArray: UseMediaReturnValue = [mediaImage.image, mediaImage.alt, mediaImage.url];
    mediaArray.image = mediaImage.image;
    mediaArray.alt = mediaImage.alt;
    mediaArray.url = mediaImage.url;

    return mediaArray;
}

function getMediaImage(
    media: IMedia[] | undefined = [],
    relation: number,
    fallback?: number | undefined
): IMediaImage {
    const mediaItem = getMediaWithRelation(media, relation, true, fallback);
    return {
        image: mediaItem?.localFile && getImage(mediaItem.localFile),
        alt: mediaItem?.alt || '',
        url: mediaItem?.url || '',
    };
}
